import React, { useContext } from 'react';
import { Link } from "gatsby";
import { WHITE, DARK_BLUE, PURPLE } from '../../assets/colors';
import { TranslationContext } from '../../translation/translationContext';
import { SessionContext } from '../../session/sessionContext';
import TranslationView from '../views/translationView';
import { insideNavbarHeight } from '../../assets/dimensions';
 
const styles = {
    container: {
        width: '100%',
        top: 0,
        left: 0,
        position: 'fixed',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: insideNavbarHeight,
        backgroundColor: DARK_BLUE,
    },
    leftSide: {
        marginLeft: 48,
    },
    center: {
        alignSelf: 'center'
    },
    right: {
        marginRight: 48
    },
    link: {
        marginLeft: 30,
        marginRight: 30,
        textDecoration: 'none',
        color: WHITE,
        fontFamily: "Coda", // TODO
        fontWeight: 400,
        fontSize: 20,
    },
    blurryLink: {
        opacity: 0.5
    }
};

const renderLinks = (links = [], style, currentTab = null) => links.map(({ to, text, onClick }) => (
    <Link key={to} style={{...styles.link, ...style, color: to === currentTab ? PURPLE : WHITE}} to={to} onClick={onClick}>{text}</Link>
));

const Navbar = ({ currentTab }) => {
    const { translate } = useContext(TranslationContext);
    const { logout } = useContext(SessionContext);

    const centerLinks = [
        {text: translate('home'), to: '/member'},
        {text: translate('news'), to: '/news'},
        {text: translate('events'), to: '/events'},
        {text: translate('library'), to: '/library'},
        {text: translate('support'), to: '/support'},
    ];
    
    const rightLinks = [
        {text: translate('logout'), to: '/', onClick: logout }
    ];

    return (
        <div style={styles.container}>
            <div style={styles.leftSide}>
                <TranslationView />
            </div>
            <div style={styles.center}>
                {renderLinks(centerLinks, {}, currentTab)}
            </div>
            <div style={styles.right}>
                {renderLinks(rightLinks, styles.blurryLink)}
            </div>
        </div>
    );
}

export default Navbar;