import React from "react";

const styles = {
    searchBar: {
      fontSize: 20,
      fontFamily: 'Roboto',
      fontWeight: 400,
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      borderRadius: 8,
      marginBottom: 48,
    }
};

const SearchBar = ({ style, value, setValue, placeholder }) => (
    <input
        type="text"
        placeholder={placeholder}
        style={{...styles.searchBar, ...style}}
        value={value}
        onChange={event => setValue(event.target.value)}
    />
);

export default SearchBar;
