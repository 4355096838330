import React from "react";
import arrow from '../../assets/images/arrow.svg';
import { isBrowser } from '../../utils/globalFunctions';

const scrollToTop = () => isBrowser() && window[`scrollTo`]({ top: 0, behavior: `smooth` });

const ScrollButton = ({ style }) => (
    <img style={style} src={arrow} alt="arrow" onClick={scrollToTop} />
);

export default ScrollButton;