import React from "react";
import { BLACK } from '../../assets/colors';
import bookmark from '../../assets/images/bookmark.svg';
import share from '../../assets/images/share.svg';
import { dateToString, dateToTime } from '../../utils/dateHelper';

const styles = {
  articleContainer: {
    marginTop: 32,
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  date: {
    fontFamily: 'Inria Serif',
    fontWeight: 400,
    fontSize: 24,
    marginBottom: 16
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontWeight: 400,
    fontSize: 24,
    marginBottom: 40
  },
  image: {
    marginBottom: 40,
    height: 300,
    width: 500
  },
  text: {
    fontFamily: 'Roboto Slab',
    fontWeight: 400,
    fontSize: 17,
    marginBottom: 24,
    'line-height': '150%'
  },
  publisherContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  publishedText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 17,
    fontStyle: 'italic'
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  bookmark: {
    width: 25,
    height: 25,
    alignSelf: 'flex-end'
  },
  share: {
    width: 30,
    height: 30,
    marginLeft: 16,
  },
  line: {
    width: '100%',
    height: 1,
    color: BLACK,
    backgroundColor: BLACK
  }
};

const NewsArticle = ({ onBookmark, date, publisher, title, image, text }) => {
    const onShare = () => {

    };

    const publishDate = dateToString(date);
    const publishTime = dateToTime(date);
    return (
        <div style={styles.articleContainer}>
            <p style={styles.date}>{publishDate}</p>
            <p style={styles.title}>{title}</p>
            {image && <img style={styles.image} src={image} alt={title} />}
            <p style={styles.text}>{text}</p>
            <div style={styles.publisherContainer}>
                <p style={styles.publishedText}>{`Published by ${publisher} ${publishTime}`}</p>
                {/* <div style={styles.iconContainer}>
                    <img style={styles.bookmark} src={bookmark} alt="bookmark" onClick={onBookmark} />
                    <img style={styles.share} src={share} alt="share" onClick={onShare} />
                </div> */}
            </div>
            <div style={styles.line} />
        </div>
    );
};

export default NewsArticle;