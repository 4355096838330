import React, { useContext, useState, useEffect } from "react";
import Screen from '../components/views/screen';
import Navbar from '../components/navbars/insideNavbar';
import { NewsContext } from '../news/newsContext';
import { TranslationContext } from '../translation/translationContext';
import { LIGHT_BLUE } from '../assets/colors';
import { insideNavbarHeight } from '../assets/dimensions';
import ScrollButton from '../components/buttons/scrollButton';
import NewsArticle from '../components/views/newsArticle';
import SearchBar from '../components/texts/searchBar';
import logo from '../assets/images/logo.svg';

const styles = {
  container: {
    backgroundColor: LIGHT_BLUE,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: insideNavbarHeight + 48,
    paddingBottom: 300,
  },
  scrollButton: {
    position: 'fixed',
    bottom: 50,
    right: 50
  },
  infoSquare: {
    paddingRight: 48,
    paddingTop: 48,
    paddingBottom: 48,
    paddingLeft: 48,
    backgroundColor: 'white',
    "box-shadow": '4px 3px 19px 9px rgba(0, 0, 0, 0.25)',
    marginBottom: 800,
    marginTop: 150,
  },
  noNews: {
    fontFamily: 'Roboto',
    fontSize: 24,
    textAlign: 'center'
  }
};

// TODO: Share social

const NewsPage = () => {
  const { translate } = useContext(TranslationContext);
  const { news, bookmark } = useContext(NewsContext);
  const [filteredNews, setFilteredNews] = useState([]);
  const [searchPhrase, setSearchPhrase] = useState('');

  useEffect(() => {
    const searchLower = searchPhrase.toLowerCase();
    setFilteredNews(news.filter(d => d.title.toLowerCase().includes(searchLower) || d.text.toLowerCase().includes(searchLower)));
  }, [searchPhrase]);

  return (
    <Screen style={styles.container}>
      <Navbar currentTab={'/news'} />
      {!!news.length && (
        <SearchBar
          placeholder={translate('searchNews')}
          value={searchPhrase}
          setValue={setSearchPhrase}
        /> 
      )}
      {filteredNews.map(({ id, date, publisher, title, image, text }) => (
          <NewsArticle
            onBookmark={() => bookmark(id)}
            date={date}
            publisher={publisher}
            title={title}
            image={image}
            text={text} 
          />
      ))}

      {!!news.length && !filteredNews.length && (
          <p style={styles.noNews}>{translate('noMatchingNews')}</p>
      )}
      {!news.length && (
        <div style={styles.infoSquare}>
          <img src={logo} alt="logo" />
          <p style={styles.noNews}>{translate('noNews')}</p>
        </div>
      )}
      <ScrollButton style={styles.scrollButton} />
    </Screen>
  )
}

export default NewsPage;
